<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col class="text-center">
                        <v-btn @click="setMinAll" color="primary">МИН</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn @click="setAvgAll" color="primary">СРЕДН</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn @click="setMaxAll" color="primary">МАКС</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-expansion-panels multiple>
                    <v-expansion-panel v-for="sh in schemes" :key="sh.id">
                        <v-expansion-panel-header class="pa-0 pr-4">
                            <v-col cols="10">
                                <v-row justify="space-between" no-gutters>
                                    <v-col cols="12">
                                        <b>{{ sh.name }}:</b>
                                        {{ sh.description }}
                                    </v-col>
                                    <v-col cols="12">
                                        ЖВНЛП:
                                        <b>{{ sh.zvnlp ? "ДА" : "НЕТ" }}</b>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2">
                                <v-row justify="space-between" no-gutters>
                                    <v-col class="text-center">
                                        <v-btn
                                            color="primary"
                                            @click.native.stop="
                                                setMinScheme(sh)
                                            "
                                            >МИН
                                        </v-btn>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-btn
                                            color="primary"
                                            @click.native.stop="
                                                setAvgScheme(sh)
                                            "
                                            >СРЕДН
                                        </v-btn>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-btn
                                            color="primary"
                                            @click.native.stop="
                                                setMaxScheme(sh)
                                            "
                                            >МАКС
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-expansion-panels multiple>
                                <v-expansion-panel
                                    v-for="drug in sh.drugs_with_sizes"
                                    :key="drug.id"
                                >
                                    <v-expansion-panel-header class="pa-0 pr-4">
                                        <v-col cols="10">
                                            <v-row
                                                justify="space-between"
                                                no-gutters
                                            >
                                                <v-col cols="12">
                                                    {{ drug.name }}
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row
                                                justify="space-between"
                                                no-gutters
                                            >
                                                <v-col class="text-center">
                                                    <v-btn
                                                        @click.native.stop="
                                                            setMinDrug(drug)
                                                        "
                                                        color="primary"
                                                        >МИН
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="text-center">
                                                    <v-btn
                                                        @click.native.stop="
                                                            setAvgDrug(drug)
                                                        "
                                                        color="primary"
                                                        >СРЕДН
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="text-center">
                                                    <v-btn
                                                        @click.native.stop="
                                                            setMaxDrug(drug)
                                                        "
                                                        color="primary"
                                                        >МАКС
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-radio-group
                                            v-model="drug.choosenSize"
                                            mandatory
                                        >
                                            <v-radio
                                                v-for="size in drug.sizes"
                                                :key="size"
                                                :label="size"
                                                :value="size"
                                            />
                                        </v-radio-group>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="proceed">Результаты</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PlainSchemesOptions",
    data: () => ({
        schemes: []
    }),
    mounted() {
        this.axios
            .get(`/api/schemes-plain-options`, {
                params: { schemes: this.$store.state.test.schemes }
            })
            .then(resp => {
                this.schemes = resp.data;
                this.schemes.forEach(sh => {
                    sh.drugs_with_sizes.forEach(drug => {
                        drug.sizes.sort((a, b) => a - b);
                        drug.choosenSize = drug.sizes[0];
                    });
                });
            });
    },
    methods: {
        setMinDrug(drug) {
            drug.choosenSize = drug.sizes[0];
        },
        setAvgDrug(drug) {
            drug.choosenSize = String(
                drug.sizes.reduce((a, b) => a + Number(b), 0) /
                    drug.sizes.length
            );
        },
        setMaxDrug(drug) {
            drug.choosenSize = drug.sizes[drug.sizes.length - 1];
        },
        setMinScheme(sh) {
            sh.drugs_with_sizes.forEach(drug => {
                this.setMinDrug(drug);
            });
        },
        setAvgScheme(sh) {
            sh.drugs_with_sizes.forEach(drug => {
                this.setAvgDrug(drug);
            });
        },
        setMaxScheme(sh) {
            sh.drugs_with_sizes.forEach(drug => {
                this.setMaxDrug(drug);
            });
        },
        setMinAll() {
            this.schemes.forEach(sh => {
                sh.drugs_with_sizes.forEach(drug => {
                    this.setMinDrug(drug);
                });
            });
        },
        setAvgAll() {
            this.schemes.forEach(sh => {
                sh.drugs_with_sizes.forEach(drug => {
                    this.setAvgDrug(drug);
                });
            });
        },
        setMaxAll() {
            this.schemes.forEach(sh => {
                sh.drugs_with_sizes.forEach(drug => {
                    this.setMaxDrug(drug);
                });
            });
        },
        proceed() {
            const res = [];
            this.schemes.forEach(sh => {
                const drugs = [];
                sh.drugs_with_sizes.forEach(drug => {
                    drugs.push({ drugId: drug.id, drugSize: drug.choosenSize });
                });
                res.push({ schemeId: sh.id, drugs: drugs });
            });
            this.$store.commit("setPlainSchemes", res);
            this.$router.push(`/test-results`);
        }
    }
};
</script>

<style scoped></style>
